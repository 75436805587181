import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faFax } from '@fortawesome/free-solid-svg-icons'

const ContactPage = () => (
  <Layout>
    <PageTitle title="Contact Sean Becker" page="PageTitleContact" />
    <Seo title="Contact Sean Becker, Attorney in Maryland" description=" The Law Office of Sean E. Becker, LLC is conveniently located just off of route 50 in Annapolis MD.  " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            The Law Office of Sean E. Becker, LLC is conveniently located just off of route 50 in Annapolis MD. Heading eastbound on route 50, take exit 29B towards College Parkway. Go straight at the light across College Parkway and the road becomes Cape Saint Claire Road. Go past the fire station on the right and the office is in the shopping center on the left just past the Grauls grocery store. Although the office is located in Annapolis, Maryland He practices throughout Anne Arundel County and Queen Anne's County. For select cases, Sean has handled cases throughout Baltimore County, Talbot County, and Worcester County. 
            </p>  
            <div class="row">
            <div className="col-12 col-md-6">
            <p>
            <FontAwesomeIcon icon={faPhone} /> 410-757-5650<br />
            <FontAwesomeIcon icon={faFax} /> 410 757-5652 fax<br />
            <FontAwesomeIcon icon={faEnvelope} /> sbecker@seanbeckerlaw.com<br />                                
            </p> 
            <div>
            Hours:<br />
            Mon - Fri 8:00 am - 5:00 pm<br />
            Sat - Sun Closed<br />
            Please call for an appointment
            </div>
            </div>
            <div className="col-12 col-md-6">
            <p>
            1362 Cape St. Claire Road<br />
            Annapolis, MD 21409 
            <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.9828678759136!2d-76.44134688438095!3d39.03851004621845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b802bc1eca2411%3A0xe22c60aa92d94b30!2s1362%20Cape%20St%20Claire%20Rd%2C%20Annapolis%2C%20MD%2021409!5e0!3m2!1sen!2sus!4v1640095028919!5m2!1sen!2sus" width="400" height="300" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
            </p>
            </div>
            </div>
            
            
            
        </div> 
        
    </div>        
  </Layout>
)

export default ContactPage
